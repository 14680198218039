import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full p-6 flex flex-col items-center gap-4 border-b border-surface-200" }
const _hoisted_2 = {
  key: 0,
  class: "fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
}

import DynamicDialog from 'primevue/dynamicdialog';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import ProgressSpinner from 'primevue/progressspinner';

import useSpinner from "@/services/spinner.service"; // Use PrimeVue spinner


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { isLoading } = useSpinner();

console.log('Version: 0.0.1.10')

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _createVNode(_unref(Toast)),
    _createVNode(_unref(DynamicDialog)),
    _createVNode(_unref(ConfirmDialog), { group: "templating" }, {
      message: _withCtx((slotProps) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("i", {
            class: _normalizeClass([slotProps.message.icon, "!text-6xl text-primary-500"])
          }, null, 2),
          _createElementVNode("p", null, _toDisplayString(slotProps.message.message), 1)
        ])
      ]),
      _: 1
    }),
    (_unref(isLoading))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_unref(ProgressSpinner))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})