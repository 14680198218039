import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import {authGuard} from '@/guards/authGuard';
import {adminGuard} from "@/guards/adminGuard";
import undefined from '@/views/_secure/reports/course-results.component.vue';

const Login = () => import(/* webpackChunkName: "login" */ '@/views/_public/login.component.vue');
const Verify = () => import(/* webpackChunkName: "verify" */ '@/views/_public/verify.component.vue');
const Layout = () => import(/* webpackChunkName: "layout" */ '@/views/_secure/layout/layout.component.vue');
const MyDashboard = () => import(/* webpackChunkName: "my-dashboard" */ '@/views/_secure/my-dashboard/my-dashboard.component.vue');
const CoursesOverview = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/courses/courses-overview.component.vue');
const CourseAdd = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/courses/course-add.component.vue');
const CourseDetails = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/courses/course-details.component.vue');
const CoursesCategoryOverview = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/courses/category/course-categories-overview.component.vue');
const CourseCategoryDetails = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/courses/category/course-category-details.component.vue');
const CoursesLessonPlanOverview = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/courses/lesson-plan/course-lesson-plans-overview.component.vue');
const CourseLessonPlanDetails = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/courses/lesson-plan/course-lesson-plan-details.component.vue');
const CompanyDetails = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/companies/company-details.component.vue');
const CompaniesOverview = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/companies/companies-overview.component.vue');
const UserDetails = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/users/user-details.component.vue');
const UsersOverview = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/users/users-overview.component.vue');
const Import = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/users/import/import.component.vue');
const Reports = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/reports/reports.component.vue');
const Results = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/reports/course-results.component.vue');
const EmployeeGroupDetails = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/users/employee-group/employee-group-details.component.vue');
const EmployeeGroupsOverview = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/users/employee-group/employee-groups-overview.component.vue');
const Settings = () => import(/* webpackChunkName: "courses-overview" */ '@/views/_secure/settings/settings.component.vue');

const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/verify',
        name: 'Verify',
        component: Verify,
    },
    {
        path: '/secure',
        component: Layout,
        beforeEnter: authGuard,
        children: [
            {
                path: 'my-dashboard',
                name: 'MyDashboard',
                component: MyDashboard,
            },
            {
                path: 'courses-overview',
                name: 'CoursesOverview',
                component: CoursesOverview,
            },
            {
                path: 'course-add',
                name: 'CourseAdd',
                component: CourseAdd,
                beforeEnter: adminGuard,
            },
            {
                path: 'course-details',
                name: 'CourseDetails',
                component: CourseDetails,
            },
            {
                path: 'course-categories-overview',
                name: 'CoursesCategoryOverview',
                beforeEnter: adminGuard,
                component: CoursesCategoryOverview,
            },
            {
                path: 'course-category-details',
                beforeEnter: adminGuard,
                name: 'CourseCategoryDetails',
                component: CourseCategoryDetails,
            },
            {
                path: 'course-lesson-plans-overview',
                name: 'CoursesLessonPlanOverview',
                component: CoursesLessonPlanOverview,
            },
            {
                path: 'course-lesson-plan-details',
                name: 'CourseLessonPlanDetails',
                component: CourseLessonPlanDetails,
            },
            {
                path: 'companies-overview',
                name: 'CompaniesOverview',
                component: CompaniesOverview,
            },
            {
                path: 'company-details',
                name: 'CompanyDetails',
                component: CompanyDetails,
            },
            {
                path: 'users-overview',
                name: 'UsersOverview',
                component: UsersOverview,
            },
            {
                path: 'user-details',
                name: 'UserDetails',
                component: UserDetails,
            },
            {
                path: 'users/employee-groups-overview',
                name: 'EmployeeGroupsOverview',
                component: EmployeeGroupsOverview,
            },
            {
                path: 'users/employee-group-details',
                name: 'EmployeeGroupDetails',
                component: EmployeeGroupDetails,
            },
            {
                path: 'users/import',
                name: 'Import',
                beforeEnter: adminGuard,
                component: Import,
            },
            {
                path: 'reports',
                name: 'Reports',
                component: Reports,
            },
            {
                path: 'results',
                name: 'CourseResults',
                component: Results,
            },
            {
                path: 'settings',
                name: 'Settings',
                component: Settings,
            },
        ],
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/secure/my-dashboard',
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
