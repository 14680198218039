<template>
  <router-view></router-view>
  <Toast/>
  <DynamicDialog/>
  <ConfirmDialog group="templating">
    <template #message="slotProps">
      <div class="w-full p-6
                  flex flex-col items-center gap-4
                  border-b border-surface-200">
        <i :class="slotProps.message.icon" class="!text-6xl text-primary-500"></i>
        <p>{{ slotProps.message.message }}</p>
      </div>
    </template>
  </ConfirmDialog>

  <!-- Spinner Overlay -->
  <div v-if="isLoading" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <ProgressSpinner/>
  </div>
</template>

<script setup lang="ts">
import DynamicDialog from 'primevue/dynamicdialog';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import ProgressSpinner from 'primevue/progressspinner';

import useSpinner from "@/services/spinner.service"; // Use PrimeVue spinner

const { isLoading } = useSpinner();

console.log('Version: 0.0.1.10')
</script>
